/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 function SEO({ description, lang, meta, image: metaImage, title }) {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
             siteUrl
           }
         }
       }
     `
   )
   const metaDescription = description || site.siteMetadata.description
   const image =
     metaImage && metaImage.src
       ? `${site.siteMetadata.siteUrl}${"images/slide16.png/"}`
       : null
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={"Study Up"}
       titleTemplate={`%s | ${site.siteMetadata.description}`}
       meta={[
         {
           name: `description`,
           content: metaDescription,
         },
         {
           property: `og:title`,
           content: title,
         },
         {
           property: `og:description`,
           content: metaDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
           name: `twitter:title`,
           content: "Study Up",
         },
         {
           name: `twitter:description`,
           content: "Justice requires more than just tweaking the numbers.",
         },
       ]
         .concat(
           metaImage
             ? [
                 {
                   property: "og:image",
                   content: image,
                 },
                 {
                   property: "og:image:width",
                   content: metaImage.width,
                 },
                 {
                   property: "og:image:height",
                   content: metaImage.height,
                 },
                 {
                   name: "twitter:card",
                   content: image,
                 },
               ]
             : [
                 {
                   name: "twitter:card",
                   content: "images/slide16.png/",
                 },
               ]
         )
         .concat(meta)}
     />
   )
 }
 SEO.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
 }
 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   image: PropTypes.shape({
     src: PropTypes.string.isRequired,
     height: PropTypes.number.isRequired,
     width: PropTypes.number.isRequired,
   }),
 }
 export default SEO
